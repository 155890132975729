import React from 'react'
import { Link as RemixLink, type LinkProps } from '@remix-run/react'
import { useLinkPrefixedWithLocale } from '~/hooks/useLinkPrefixedWithLocale'
import { cn } from '~/utils/cn'
import * as styles from '../Button/Button.styles'

export type LinkButtonProps = LinkProps & styles.Variants & { testId: string }

const LinkButton = ({
  className,
  rounded,
  color,
  fullWidth,
  variant,
  size,
  children,
  to,
  testId,
  ...restLinkProps
}: LinkButtonProps) => {
  const toPrefixed = useLinkPrefixedWithLocale(to)

  return (
    <RemixLink
      className={cn(
        className,
        styles.buttonVariants({ rounded, color, fullWidth, variant, size })
      )}
      to={toPrefixed}
      data-testid={`${testId}LinkButton`}
      {...restLinkProps}
    >
      {children}
    </RemixLink>
  )
}

export default LinkButton
